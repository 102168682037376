<template>
<div>
  <NavBar name="小区管理"></NavBar>
  <div class="datapreview">
    <el-form :inline="true" label-width="10px" class="content-main">
      <el-form-item>
        <el-input placeholder="请输入小区名称" clearable v-model="form.market_name"></el-input>
      </el-form-item>
      <el-form-item >
        <el-input v-model="form.market_contacts" clearable placeholder="请输入管理员姓名"></el-input>
      </el-form-item>
      <el-form-item label="">
        <myButton title="搜索" @click="getList">
          <template #preImage>
            <img src="@/unit/img/search.png" alt="" />
          </template>
        </myButton>
      </el-form-item>
      <el-form-item>
        <myButton title="新增" @click="visible = true">
          <template #preImage>
            <img src="@/unit/img/zj.png" alt="" />
          </template>
        </myButton>
      </el-form-item>
      <el-form-item>
        <myButton title="导出" @click="exportDataEvent">
          <template #preImage>
            <img src="@/unit/img/down.png" alt="" />
          </template>
        </myButton>
      </el-form-item>
    </el-form>
    <div class="pub-table">
      <vxe-table ref="xTable1" :row-config="{ isHover: true }" :show-overflow="showOverflow1" :data="tableData" header-cell-class-name="table-header" cell-class-name="table-cell">
        <vxe-column field="market_no" title="小区编号"></vxe-column>
        <vxe-column field="market_name" title="小区名称"></vxe-column>
        <vxe-column field="enterprise_info_value.mkt_mrch_Id" title="收款商户编号"></vxe-column>
        <vxe-column field="market_contacts" title="管理员姓名"></vxe-column>
        <vxe-column field="market_mobile" title="联系电话"></vxe-column>
        <vxe-column field="market_address" title="地址信息"></vxe-column>
        <vxe-column field="editTime" title="操作" width="120px">
          <template #default="{ row }">
            <pop tips="修改" @myclick="editShow(row)">
              <img class="icon" src="@/assets/img/icon/edit.png" alt />
            </pop>
            <pop tips="停用" v-if="row.status == 0" @myclick="statusChange(row)" style="margin: 0 16px">
              <img style="width: 18px; height: 14px" src="@/assets/img/icon/kai.png" alt />
            </pop>
            <pop tips="启用" style="margin: 0 16px" v-else @myclick="statusChange(row)">
              <img style="width: 18px; height: 14px" src="@/assets/img/icon/guan.png" alt />
            </pop>
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager :current-page="form.pageNum" :page-size="10" :total="total" :layouts="['PrevPage', 'JumpNumber', 'NextPage', , 'Total']" @page-change="getList">
      </vxe-pager>
    </div>
  </div>
  <!-- 编辑 -->
  <el-dialog v-model="visible" :append-to-body="true" title="新增/编辑小区" @close="close">
    <el-form label-width="100px" ref="addForm" :model="addForm" :rules="addRules">
      <el-form-item label="小区名称" prop="market_name">
        <el-input style="width: 196px" v-model="addForm.market_name" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="地址信息" prop="market_address">
        <el-input placeholder="请输入地信息" v-model="addForm.market_address" style="width: 196px"></el-input>
      </el-form-item>
      <el-form-item label="收款账户名">
        <el-select @change="accountChange" :popper-append-to-body="false" popper-class="selectBox" :teleported="false" v-model="account">
          <el-option v-for="item in enterprise_merchant_info" :value="item.id" :label="item.mkt_mrch_Nm" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收款商户编号">
        <el-input style="width: 196px" disabled placeholder="收款商户编号" v-model="serialNumber"></el-input>
      </el-form-item>
      <el-form-item label="管理员姓名" prop="market_contacts">
        <el-input placeholder="请输入管理员姓名" v-model="addForm.market_contacts" style="width: 196px"></el-input>
      </el-form-item>
      <el-form-item label="管理员电话" prop="market_mobile">
        <el-input placeholder="请输入管理员联系电话" v-model="addForm.market_mobile" style="width: 196px"></el-input>
      </el-form-item>
      <el-form-item label="证件号码" prop="id_number">
        <el-input placeholder="请输入证件号码" v-model="addForm.id_number" style="width: 196px"></el-input>
      </el-form-item>
    </el-form>
    <template v-slot:footer>
      <myButton @click="visible = false">取消</myButton>
      <myButton left type="primary" @click="add">确定</myButton>
    </template>
  </el-dialog>
</div>
</template>

<script>
export default {
  data() {
    return {
      showOverflow1: false,
      tableData: [],
      visible: false,
      form: {
        pageNum: 1,
        pageSize: 10,
        market_name: '',
        market_contacts: '',
        status: -1
      },
      addForm: {
        market_name: "",
        market_contacts: "",
        market_mobile: "",
        id_number: "",
        market_address: "",
        market_cover: "",
        enterprise_info: '',
      },
      addRules: {
        market_name: [{
          required: true,
          trigger: "blur",
          message: "请输入小区名称"
        }],
        market_contacts: [{
          required: true,
          trigger: "blur",
          message: "请输入管理员姓名"
        }],
        market_mobile: [{
          required: true,
          trigger: "blur",
          message: "请输入管理员联系电话"
        }],
        id_number: [{
          required: true,
          trigger: "blur",
          message: "请输入证件号码"
        }, ],
        market_address: [{
          required: true,
          trigger: "blur",
          message: "请输入地址信息"
        }],
        account: [{
          required: true,
          trigger: "blur",
          message: "请选择收款账户"
        }]
      },
      total: 0,
      account: "",
      serialNumber: '',
      enterprise_merchant_info: [],
    };
  },
  created() {
    this.getuserInfo()
    this.getList();
  },
  methods: {
    close() {
      this.addForm = {
        market_name: '',
        market_contacts: '',
        market_mobile: '',
        id_number: '',
        market_address: '',
        market_cover: '',
        enterprise_info: '',
      };
      this.account = '';
      this.serialNumber = '';
    },
    getToken() {
      if (token) {
        this.getuserInfo();
      }
    },
    getQueryVariable(val) {
      const w = window.location.hash.indexOf("?");
      const query = window.location.hash.substring(w + 1);
      const vars = query.split("&");
      for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == val) {
          return pair[1];
        }
      }
      return null;
    },
    getuserInfo() {
      this.$request.HttpPost('/admin/enterprise').then((res) => {
        if (res.data) {
          this.enterprise_merchant_info = JSON.parse(res.data.enterpriseMerchantInfo);
        }
      });
    },
    exportDataEvent() {
      this.$refs.xTable1.exportData({
        type: "csv",
        filename: `小区列表${new Date().toLocaleString()}`,
        columnFilterMethod ({ column }) {
          return ['market_no', 'market_name', 'market_name', 'enterprise_info_value.mkt_mrch_Id', 'market_contacts', 'market_mobile', 'market_address'].includes(column.property)
        }
      });
    },
    getList(e = {
      currentPage: 1
    }) {
      this.form.pageNum = e.currentPage;
      this.tableData = [];
      this.$request.HttpPost("/community/property/list-community", this.form).then((res) => {
        if (res.data.list) {
          res.data.list.forEach(item => {
            if (item.setting) {
              item.setting = JSON.parse(item.setting);
            }
          });
          this.tableData = res.data.list;

        }
        this.total = res.data.total;
      });
    },
    add() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let url = '/community/property/add-community';
          if (this.addForm.market_id) {
            url = '/community/property/edit-community';
          }
          this.$request.HttpPost(url, this.addForm).then(() => {
            this.visible = false;
            this.getList();
          });
        }
      });
    },
    statusChange(e) {
      this.$request.HttpPost('/community/property/edit-community-status', {
        market_id: e.market_id,
      }).then(() => {
        this.getList();
      });
    },
    editShow(data) {
      let item = JSON.parse(JSON.stringify(data));
      this.account = '';
      if (item.enterprise_info_value) {
        item.enterprise_info = item.enterprise_info_value;
        this.account = item.enterprise_info_value.id;
        this.serialNumber = item.enterprise_info_value.mkt_mrch_Id;
      }
      this.addForm = item;
      this.visible = true;
    },
    accountChange(e) {
      let obj = null;
      this.enterprise_merchant_info.forEach(item => {
        if (item.id == e) {
          obj = item;
        }
      })
      this.serialNumber = obj.mkt_mrch_Id;
      this.addForm.enterprise_info = obj;
    },
  },
};
</script>
<style lang="scss">
.pub-table {
    height: calc(100vh - 160px);
}
</style>
